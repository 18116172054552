@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins-Regular-1";
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Regular-1";
  src: url("./fonts/Roboto/Roboto-Regular.ttf");
}

body {
  margin: 0;
  font-family: "Poppins-Regular-1" -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-slide {
  display: flex !important;
  justify-content: center;
}
.swiper-pagination {
  position: relative !important;
  margin-top: 20px;
}
.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 2s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.del200 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.dur1600 {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
}

.revealWidth {
  margin: 0;
  font-size: 100px;
  animation: reveal 5s ease-in-out;
}

@keyframes reveal {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}
